import { http } from './env'

export const login = data => {
  return http({
    url: '/login',
    data: data,
    method: 'POST'
  })
}

export const newsList = data => {
  return http({
    url: '/information',
    data: data
  })
}

export const newsAdd = data => {
  return http({
    url: '/information',
    data: data,
    method: 'POST'
  })
}
export const newsDetail = data => {
  return http({
    url: '/information/' + data.id
  })
}

export const newsDel = data => {
  return http({
    url: '/information/' + data.id,
    method: 'DELETE'
  })
}

export const getToken = () => {
  return http({
    url: '/qn/uptoken'
  })
}

export const uploadImg = data => {
  return http({
    url: 'https://upload-z1.qiniup.com',
    data: data,
    method: 'POST',
    type: 'multipart/form-data'
  })
}

export const concatList = data => {
  return http({
    url: '/contact',
    data: data
  })
}
